.collections-box {
	overflow-x: hidden;
	overflow-y: auto;
	padding-top: 56px;
	padding-bottom: 56px;
}

.collections-box {
	width: 100%;
	margin: 3rem auto 0px;
}


.add-button {
	border-color: #c227b9 !important;
	border-radius: 8px !important;
	width: 100%;
	Padding: 16px 24px !important;
}


..add-button:hover {
	background-color: #c227b9;
	Color: #ffffff
}


.add-button:active {
	background-color: #c227b9;
	Color: #ffffff
}


.collection-confirm-button {
	border-radius: 8px !important;
	float: right;
	Padding: 16px 24px !important;
	width: 100%;
}

	.collection-confirm-button:hover {
		background-color: #c227b9;
		Color: #ffffff
	}


	.collection-confirm-button:active {
		background-color: #c227b9;
		Color: #ffffff
	}

.confirm-button:focus, .add-button:focus {
	border: none;
}

.collection-input {
	margin-bottom: 10px;
}

	.collection-input > .form-control {
		display: block;
		width: 100%;
		min-width: 90px;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 2;
		color: #212529;
		background-color: Background;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		appearance: none;
		border-radius: 8px;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		margin-top: 5px;
		font-family: Poppins;
	}


.camera-img {
	height: auto;
	width: auto;
	max-height:inherit;
	max-width: 50%;
	cursor: pointer;
}

.error-text {
	color: red;
	font-size: 12px;
	margin-top: -20px;
}

.invalid {
	color: red !important;
	background-color: #c7b4c6 !important;
}

.collection-input > input.form-control:focus {
	outline: none !important;
	outline-width: 0 !important;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	border: 3px solid #ce54c7;
}


@media ( max-width : 1200px ) {
	iframe {
		width: 600px;
		/* Adjusted width for mobile */
		height: 338px;
		/* Adjusted height for mobile */
	}
}

@media ( max-width : 768px ) {
	iframe {
		width: 300px;
		/* Adjusted width for mobile */
		height: 169px;
		/* Adjusted height for mobile */
	}
}

@media only screen and (min-width: 450px) {
	.collections-box {
		height: 320px;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 8px;
	}

}

.fa-trash {
	color: red;
	cursor: pointer;
	padding-top: 15px;
	font-size: 20px;
	margin-left: 20px;
}

.react-confirm-alert-body > h1 {
	color: red;
	font-weight: bold;
}

.react-confirm-alert-body {
	width: 400px !important;
	margin-top: -250px;
}

.popup-confirm-button {
	background: rgb(201, 39, 39) !important;
	width: 125px !important;
}

.cancel-button {
	background: #ce54c7 !important;
	width: 125px !important;
}

.video-view {
	text-align: center;
}

.iframe {
	margin: 0 auto;
	width: 80%;
	/* border: 0;
    width: 550px;
    height: 450px; */
}

.mt-0 {
	padding-top: 56px;
	padding-bottom: 56px
}

.custom-button {
	border-radius: 8px;
	padding: 16px 24px;
	background-color: #C227B9;
	color: #ffffff;
	font-weight: bold;
	text-transform: uppercase;
	font-style: normal;
	border: none;
	cursor: pointer;
	display: inline-block;
}

.bg-secondary {
	padding-top: 56px;
}



.text-muted {
	--bs-text-opacity: 1;
	color: #003E57 !important;
}

