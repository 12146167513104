.login-content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.login-form {
    padding: 10px;
    width: 350px;
    height: 350px;
    background-color: #fff;
    box-shadow: 2px 2px 2px gray;
}

.text-primary {
    color: #c227b9 !important;
}

.text-primary:hover {
    color:rgb(206, 84, 199)!important;
}

.app-1ptv28q {
    max-width: 460px;
    padding: 15px;
    margin: auto;
}

.app-bz3gyv h1 {
    margin: 1rem auto;
}

.title-h1 {
    margin: 2rem auto;
    font-weight: 620;
    font-size: 2.85rem;
    letter-spacing: -.0165rem;
    flex: 1 1 0%;
}

.my-5 {
    margin-bottom: 3rem!important;
    margin-top: 1.85rem!important;
}

.app-tbe3dj {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 1.25rem 0 #0000000f;
    border-radius: 4px;
    padding: 1.25rem;
}

.app-bz3gyv em {
    color: #C227B9;
    font-style: normal;
}

.btn-primary {
    color: #fff;
    background-color: #C227B9;
    border-color: #C227B9;
    border-radius: 0;
  }

.btn-primary:hover {
    color: #fff;
    background-color: rgb(206, 84, 199);
    border-color: rgb(206, 84, 199);
    border-radius: 0;
  }

.form-floating > .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 5px;
    border-radius: 8px !important;
}

.form-floating > .form-control {
    border-radius: 0;
    border-left-color: #0000;
    border-right-color: #0000;
    border-top-color: #0000;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
}

.form-floating > input.form-control:focus {

    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border: 3px solid rgb(194, 187, 187);
    border-radius: 1px;
}

.field-gap {
    margin-bottom: 20px;
}

.login-button {
    background-color: #C227B9;
    width: 100%;
    border-color: #C227B9;
    height: 2.5rem;
    font-weight: 500;
    color: white;
    border-radius: 8px !important;
}

@media only screen and (max-width: 450px) {
    .title-h1 {
        margin: 2rem auto;
        font-weight: 620;
        font-size: 2.2rem;
        text-align: center;
        letter-spacing: -.0165rem;
        flex: 1 1 0%;
    }
}