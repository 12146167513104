.confirm-box {
    max-width: 400px;
    padding: 15px;
    margin: auto;
    margin-top: 3rem;
    border-radius: 8px;
}

.row > input, textarea {
    margin-bottom: 20px;
}

.row > .form-control, textarea {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
    color: #212529;
    background-color: Background;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 8px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 15px;
    margin-bottom: 0px;
}

.row > input.form-control:focus, textarea.form-control:focus {
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border: 3px solid #ce54c7;
    border-radius: 8px;
}

.date-field {
    display: block;
    width: 100%;
    padding-bottom: 0.75rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
    color: #212529;
    background-color: Background;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 8px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 1px;
    margin-bottom: 0px;
}

.date-field:focus {
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border: 3px solid #ce54c7;
    border-radius: 8px;
}

.confirm-button {
    background-color: #C227B9;
    width: 100%;
    border-color: #C227B9;
    height: 2.5rem;
    font-weight: 500;
    color: white;
    border-radius: 8px !important;
}
