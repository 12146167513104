a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
  html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.app-khjyy2 {
  border-top: 0.25rem solid #C227B9;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid rgba(0,0,0,.125);
  white-space: nowrap;
}

.brand-text-color {
  color: #C227B9;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
  border-radius: 1px;
  border: 1px solid;
}

.text-primary {
  color: #C227B9;
}
/* .navbar-toggler {
  background-color: #0000;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  color: var(--bs-navbar-color);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  transition: var(--bs-navbar-toggler-transition);
} */

.nav-link {
	color: #BDCDD4;
	display: block;
	font-size: 14px;
	font-weight: 600;
	padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
	text-decoration: none;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}


.text-dark {
	Color: #003E57
}
