.confirm-text {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    margin-top: 12%;
    color: #C227B9;
}

@media only screen and (max-width: 450px) {
    .confirm-text {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        margin-top: 15%;
    }
}